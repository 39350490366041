/*  Copyright 2018 - 2021 Swiss Federal Institute of Technology Lausanne (EPFL)
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *  http://www.apache.org/licenses/LICENSE-2.0.
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 *
 *  This open source software code was developed in part or in whole in the
 *  Human Brain Project, funded from the European Union's Horizon 2020
 *  Framework Programme for Research and Innovation under
 *  Specific Grant Agreements No. 720270, No. 785907, and No. 945539
 *  (Human Brain Project SGA1, SGA2 and SGA3).
 *
 *
 *   Licensed under the Apache License, Version 2.0 (the "License");
 *   you may not use this file except in compliance with the License.
 *   You may obtain a copy of the License at
 *
 *       http://www.apache.org/licenses/LICENSE-2.0
 *
 *   Unless required by applicable law or agreed to in writing, software
 *   distributed under the License is distributed on an "AS IS" BASIS,
 *   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *   See the License for the specific language governing permissions and
 *   limitations under the License.
 *
 */

import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';

import matomo from './services/matomo';
import sentry from './services/sentry';

import API from "./services/API";
import AuthAdapter from "./services/AuthAdapter";
import KeycloakAuthAdapter from "./services/KeycloakAuthAdapter";
import APIProvider from "./components/APIProvider";
import AuthProvider from "./components/AuthProvider";
import Authenticate from "./components/Authenticate";
import Settings from "./components/Settings";

import LoginButton from './components/LoginButton';
import Error from "./components/Error";
import ContactText from './components/ContactText';

const Dataset = React.lazy(() => import("./components/Dataset"));
const Buckets = React.lazy(() => import("./components/Buckets"));

import "./App.scss";

const App = ({ api, authAdapter }: { api: API, authAdapter?: AuthAdapter}): JSX.Element => (
  <BrowserRouter>
    <APIProvider api={api}>
      <AuthProvider adapter={authAdapter} >
        <Navbar bg="light">
          <Container>
            <NavLink to={"/"} className="navbar-brand">Data Proxy</NavLink>
            <LoginButton />
          </Container>
        </Navbar>
        <Container>
          <Settings>
            {settings => {
              const isLocalDev = window.location.host.startsWith('localhost');
              sentry.initialize(isLocalDev ? undefined : settings?.sentry);
              matomo.initialize(isLocalDev ? undefined : settings?.matomo);
              api.setDataServiceUrl(settings?.dataServiceUrl);
              api.setSearchUrl(settings?.searchUrl);
              matomo.trackPageView();
              if (authAdapter instanceof KeycloakAuthAdapter) {
                if (settings?.keycloak) {
                  authAdapter.setConfig(settings?.keycloak);
                } else {
                  return (
                    <Error>
                      <h3>Failed to initilize the application.</h3>
                      <i>Service settings endpoint is missing keycloak configuration.</i><br /><br />
                      <ContactText />
                    </Error>
                  );
                }
              }
              return (
                <Authenticate>
                  <Suspense fallback={<Spinner animation="border" className="loading-container" />}>
                    <Routes>
                      <Route path="/datasets/:datasetId" element={<Dataset />} />
                      <Route path="/*" element={<Buckets />} />
                    </Routes>
                  </Suspense>
                </Authenticate>
              );
            }} 
          </Settings>
        </Container>
      </AuthProvider>
    </APIProvider>
  </BrowserRouter>
);

export default App;
