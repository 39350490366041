/*
 * Copyright 2018 - 2021 Swiss Federal Institute of Technology Lausanne (EPFL)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0.
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * This open source software code was developed in part or in whole in the
 * Human Brain Project, funded from the European Union's Horizon 2020
 * Framework Programme for Research and Innovation under
 * Specific Grant Agreements No. 720270, No. 785907, and No. 945539
 * (Human Brain Project SGA1, SGA2 and SGA3).
 *
 */

import React from "react";
import Button from "react-bootstrap/Button";

import { Settings as SettingsType } from "../types";
import useGetSettingsQuery from "../hooks/useGetSettingsQuery";
import Fetching from "./Fetching";
import Error from "./Error";
import ContactText from "./ContactText";

interface SettingsProps {
  children: (settings?: SettingsType) => null|JSX.Element|(null|JSX.Element)[];
}

const Settings = ({ children }: SettingsProps) => {

  const {
    data: settings,
    error,
    isUninitialized,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetSettingsQuery();

  if (isError) {
    return (
        <Error>
          <h3>An error occured while retrieving application configuration.</h3>
          <i>{error}</i><br /><br />
          <ContactText /><br /><br />
          <div><Button variant="primary" onClick={refetch}>Retry</Button></div>
        </Error>
    );
  }

  if(isUninitialized || isFetching) {
    return (
      <Fetching text="Retrieving application configuration..." />
    );
  }

  if (isSuccess) {
    return (
      <>
        {children(settings)}
      </>
    );
  }

  return null;
};

export default Settings;