/*
 * Copyright 2018 - 2021 Swiss Federal Institute of Technology Lausanne (EPFL)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0.
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * This open source software code was developed in part or in whole in the
 * Human Brain Project, funded from the European Union's Horizon 2020
 * Framework Programme for Research and Innovation under
 * Specific Grant Agreements No. 720270, No. 785907, and No. 945539
 * (Human Brain Project SGA1, SGA2 and SGA3).
 *
 */

import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";

import useAuth from "../hooks/useAuth";
import AuthAdapter from "../services/AuthAdapter";
import Fetching from "./Fetching";
import Error from "./Error";
import ContactText from "./ContactText";

interface AuthenticationProps {
  adapter?: AuthAdapter;
  children?: string|JSX.Element|(string|JSX.Element)[];
}

interface AuthenticationProps {
  children?: string|JSX.Element|(string|JSX.Element)[];
}

const Authenticate = ({ children }:AuthenticationProps) => {

  const initializedRef = useRef(false);

  const {
    isTokenExpired,
    error,
    isError,
    isUninitialized,
    isInitializing,
    isAuthenticating,
    authenticate
  } = useAuth();

  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true;
      authenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(isUninitialized || isInitializing || isAuthenticating) {
    return (
      <Fetching text="User authenticating..." />
    );
  }

  if (isTokenExpired) {
    return (
      <Error>
        <h3>Your session has expired</h3><br />
        <p>Please login again.</p>
      </Error>
    );
  }

  if (isError) {
    return (
      <Error>
        <h3>There was a problem authenticating.</h3>
        <i>{error}</i><br /><br />
        <ContactText />
        <div><Button variant={"primary"} onClick={authenticate}>Retry</Button></div>
      </Error>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default Authenticate;